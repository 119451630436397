<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/raports" class="is-active"><a>Raporty</a></router-link>
        </ul>   
      </nav>
    </portal-target>
    <div class="ym-toptitle-box">
      <div  id="toptitle" class="level ym-toptitle">
        <h2 class="title level-left">Raporty</h2>
      </div>
    </div>  
    <div v-if="loading">trwa wczytywanie danych...</div>
    <div class="ym-whitebg pa40" v-else>
      <div class="columns is-variable is-6 is-multiline">
        <div class="column ym-column is-4">
          <div class="ym-label">
            <label for="f_company_full">Przedział dat</label>
            <p>Domyślnie raport z poprzedniego miesiąca.</p>
          </div>
        </div>
        <div class="column ym-column is-8">
          <b-datepicker
              :first-day-of-week="1"
              :mobile-native="false"
              placeholder="Wybierz przedział dat raportu"
              v-model="dates"
              icon="calendar-today"
              position="is-bottom-left"
              range>
          </b-datepicker>
        </div>
      </div>
      <div class="columns is-variable is-6 is-multiline">
        <div class="column ym-column is-4">
          <div class="ym-label">
            <label for="f_company_full">Serwisant</label>
          </div>
        </div>
        <div class="column ym-column is-8">
          <div class="b-field b-field-flex">
            <!-- <b-select v-model="technical_id" placeholder="Wybierz serwisanta" expanded style="min-width: 248px">
              <option :value="null">brak</option>
              <option v-for="technic in technicians" :key="technic.id" :value="technic.id">{{ technic.lastname + ' ' + technic.firstname }}</option>
            </b-select> -->
            <b-autocomplete
                v-model="name"
                placeholder="Wybierz serwisanta"
                :open-on-focus="true"
                :data="filteredDataArray"
                field="lastname"
                @select="option => technical = option"
                :custom-formatter="option => `${option.lastname} ${option.firstname}`"
                style="min-width: 248px;"
                size="is-medium"
                :clearable="true"
            >
                <template slot-scope="props">
                  {{ props.option.lastname + ' ' + props.option.firstname }}
                </template>         
                <template #empty>Brak wyników dla: {{name}}</template>
   
            </b-autocomplete>            
          </div>  
        </div>
      </div>
      <div class="columns is-variable is-6 is-multiline">
        <div class="column ym-column is-4">
          <div class="ym-label">
            <label for="f_company_full">Klient</label>
          </div>
        </div>
        <div class="column ym-column is-8">
          <div class="b-field b-field-flex small">
            <!-- <b-select v-model="technical_id" placeholder="Wybierz serwisanta" expanded style="min-width: 248px">
              <option :value="null">brak</option>
              <option v-for="technic in technicians" :key="technic.id" :value="technic.id">{{ technic.lastname + ' ' + technic.firstname }}</option>
            </b-select> -->
            <b-autocomplete
                v-model="customerName"
                placeholder="Wybierz klienta"
                :open-on-focus="true"
                :data="filteredCustomerDataArray"
                field="name"
                @select="option => customer = option"
                :custom-formatter="option => `${option.name}`"
                style="min-width: 248px;"
                size="is-medium"
                :clearable="true"
            >
                <template slot-scope="props">
                  {{ props.option.name }}
                </template>         
                <template #empty>Brak wyników dla: {{name}}</template>
   
            </b-autocomplete>            
          </div>  
        </div>
      </div>
      <div class="columns is-variable is-6 is-multiline">
        <div class="column ym-column is-4">
          <div class="ym-label">
            <label for="f_company_full">Typ</label>
            <p>Domyślnie: <b>Płatne</b>.</p>
          </div>
        </div>
        <div class="column ym-column is-8">
            <div class="ym-rowbor">
              <div class="level ym-rowbor-item">
                <div class="ym-radios">
                    <label>
                        <input type="radio" name="order_type" v-model="order_type" value="paid">
                        <span>Płatne</span>
                    </label>
                    <label>
                        <input type="radio" name="order_type" v-model="order_type" value="warranty">
                        <span>Gwarancja</span>
                    </label>
                    <label>
                        <input type="radio" name="order_type" v-model="order_type" value="service">
                        <span>Usługi</span>
                    </label>
                    <label>
                        <input type="radio" name="order_type" v-model="order_type" value="trade">
                        <span>Handel</span>
                    </label>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="columns is-variable is-6 is-multiline">
        <div class="column ym-column is-4">
          <div class="ym-label">
            <label for="f_company_full">Raport w rozszerzeniu</label>
          </div>
        </div>
        <div class="column ym-column is-8">
            <div class="ym-rowbor">
              <div class="level ym-rowbor-item">
                <div class="ym-radios">
                    <label>
                        <input type="radio" name="order_file" v-model="order_file" value="xlsx">
                        <span>*.xlsx</span>
                    </label>
                    <label>
                        <input type="radio" name="order_file" v-model="order_file" value="csv">
                        <span>*.csv</span>
                    </label>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="columns is-variable is-6 is-multiline">
        <div class="column ym-column is-4">
          <div class="ym-label">
            <label for="f_company_full">&nbsp;</label>
          </div>
        </div>
        <div class="column ym-column is-8">
            <a @click.prevent="download" class="button is-medium is-dark"><icon name="file-text"></icon><span>Pobierz</span></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Raports',
  data() {
    return {
      order_type: 'paid',
      order_file: 'xlsx',
      technicians: [],
      customers: [],
      loading: false,
      technical_id: null,
      name: '',
      technical: null,
      customerName: '',
      customer: null,
    }
  },
  created() {
    this.getUsers()
    this.getCustomers()
  },
  computed: {
    ...mapGetters({
      raports: 'auth/raports'
    }),
    dates() {
        let now = new Date()
        let lastday  = new Date(now.getFullYear(), now.getMonth(), 0)
        let firstday = new Date(lastday.getFullYear(), lastday.getMonth(), 1)

        return [firstday, lastday]
    },
    date_from() {
      return this.dates[0]
    },
    date_to() {
      return this.dates[1]
    },    
    filteredCustomerDataArray() {
        return this.customers.filter((option) => {
           return option.name
              .toString()
              .toLowerCase()
              .indexOf(this.customerName.toLowerCase()) >= 0           
        })
    },
    filteredDataArray() {
        return this.technicians.filter((option) => {
          let lastname = option.lastname
              .toString()
              .toLowerCase()
              .indexOf(this.name.toLowerCase()) >= 0            
          let firstname = option.firstname
              .toString()
              .toLowerCase()
              .indexOf(this.name.toLowerCase()) >= 0    
          return lastname || firstname        
        })
    },
  },
  methods: {
    ...mapActions({
      getUsersCall: 'records/getUsers',
      getCustomersCall: 'records/getCustomers',
    }),
    getCustomers() {
      this.loading = true
      this.getCustomersCall({page: 1, perPage: 9999, sortField: 'name', sortOrder: 'asc', searchQuery: '', searchDeleted: 0, hideDevice: 1})
        .then(resp => {
          this.customers = resp.data
        })
        .catch((error) => {
          this.customers = []
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: 'is-bottom',
            type: 'is-danger'
          })                            
        })
        .finally(() => this.loading = false)
    },        
    getUsers() {
      this.loading = true

      this.getUsersCall({page: 1, perPage: 100, sortField: 'lastname', sortOrder: 'asc', searchQuery: '', searchDeleted: 0, function_role: 'technical'})
        .then(resp => {
          this.technicians = resp.data
        })
        .catch((error) => {
          this.technicians = []
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: 'is-bottom',
            type: 'is-danger'
          })                            
        })
        .finally(() => this.loading = false)
    },    
    download() {
      function convertDate(date) {
        var yyyy = date.getFullYear().toString();
        var mm = (date.getMonth()+1).toString();
        var dd  = date.getDate().toString();
      
        var mmChars = mm.split('');
        var ddChars = dd.split('');
      
        return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
      }

      let date_from = this.date_from ? convertDate(this.date_from) : ''
      let date_to = this.date_to ? convertDate(this.date_to) : ''

      let url;

      if(this.order_file === 'xlsx') {
        url = this.raports.orders.xlsx
      } else {
        url = this.raports.orders.csv
      }

      const params = [
        `date_from=${date_from}`,
        `date_to=${date_to}`,
        `order_type=${this.order_type}`,
        `technical_id=${this.technical ? this.technical.id : null}`,
        `customer_id=${this.customer ? this.customer.id : null}`
      ].join('&') 

      const a = document.createElement('a')
      a.href = `${url}?${params}`
      a.download = url.split('/').pop()
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
  },
}
</script>

<style>
.small .autocomplete .dropdown-item {font-size: 0.7rem !important;padding-right: 1rem;}
</style>